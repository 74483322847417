<template>
    <div>
      <base-header class="pb-6" type="primary">
        <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
      </base-header>

      <div class="container-fluid mt--6">
        <table-navigation :table-title="tableTitle"
                          :table-buttons="tableButtons"
                          :table-columns="tableColumns"
                          :table-data="tableData"
                          :router-view="routerView"
                          :connection-error="connectionError"
                          :pagination="pagination"
                          :filter-options="filterOptions"
                          :filters="tableFilters"
                          @page="changePage"
                          @filters="changeFilters"
        >
        </table-navigation>
      </div>
    </div>
  </template>

  <script>
  import {createNamespacedHelpers} from 'vuex'
  import TableNavigation from "@/views/Store/Tables/TableNavigation";
  import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";

  const {mapState, mapActions} = createNamespacedHelpers('NewsletterListModule')

  export default {
    name: 'NewsletterList',
    components: {
      BreadcrumbHeader,
      TableNavigation,
    },
    data() {
      return {
        tableTitle: 'Lista użytkowników newslettera',
        routerView: 'newsletter-view',
        tableButtons: [
          {
            label: 'Dodaj subskrybenta',
            icon: 'fas fa-edit',
            link: 'newsletter-add',
          },
        ],
        tableColumns: [
          {
            prop: 'email',
            label: 'Adres email',
            minWidth: 150,
            sortable: true,
            link: true,
          },
          {
            prop: 'createdAt',
            label: 'Data dodania',
            minWidth: 80,
            sortable: true,
          },
          {
            prop: 'updatedAt',
            label: 'Data aktualizacji',
            minWidth: 80,
            sortable: true,
          },
          {
            prop: 'active',
            label: 'Aktywny',
            minWidth: 60,
            sortable: false,
          },
        ],
        filterOptions: [
          {field: 'email', label: 'Adres email'},
          {field: 'active', label: 'Aktywny'},
        ],
      }
    },
    computed: {
      ...mapState({
        tableData: state => state.items,
        totalItems: state => state.totalItems,
        tableFilters: state => state.filterValues,
        connectionError: state => state.apiConnectionErr,
      }),
      breadcrumb() {
        return [
          {title: 'Sklep', link: {name: 'store'}},
          {title: 'Newsletter'},
        ];
      },
      handleError() {
        return `Błąd ${this.connectionError.status} - ${this.$t[this.connectionError.status]}`;
      },
      pagination() {
        return {
          totalItems: this.totalItems,
          perPage: 20,
          currentPage: parseInt(this.$route.params.pageNum || 1),
        };
      },
    },
    methods: {
      changePage(page) {
        this.$router.push({name: 'newsletter', params: {pageNum: page}});
      },
      changeFilters(filters) {
        this.$store.commit('NewsletterListModule/setFilterValues', filters);
        this.$store.dispatch('NewsletterListModule/fetchList', {
          token: this.$store.state.Auth.token,
          page: 1,
        });
      },
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('NewsletterListModule/resetItems', []);
      return next();
    },
    beforeRouteUpdate(to, from, next) {
      this.$store.commit('NewsletterListModule/resetItems', []);
      this.$store.dispatch('NewsletterListModule/fetchList', {
        token: this.$store.state.Auth.token,
        page: to.params.pageNum || 1,
      });
      return next();
    },
    beforeCreate() {
      this.$store.dispatch('NewsletterListModule/fetchList', {
        token: this.$store.state.Auth.token,
        page: this.$route.params.pageNum || 1,
      });
    },
  }
  </script>
